import React, { Component } from "react";
import { Link } from "react-router-dom";
import WorkProject from "../components/WorkProject";
import WorkHeader from "../components/WorkHeader";
import WorkOtherRole from "../components/WorkOtherRole";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/works" });

class Works extends Component {
  render() {
    return (
      <div id="works" className="container-md">
        <h1>Works</h1>

        <Link
          to={{
            pathname: `/work/${window.works[1].id}`,
          }}
        >
          {window.works[1].name}
        </Link>
        <Link
          to={{
            pathname: `/work/${window.works[0].id}`,
          }}
        >
          {window.works[0].name}
        </Link>

        <div className="row">
          <div className="col-12">
            <WorkHeader
              title="TrainerRoad - Product Manager"
              description="A cycling based company leveraging ML to optimize and personalize training for athletes"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Adaptive Training"
              description="Adjusting training to give athletes the right workout everytime"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Datadog"
              description="Enabling enchanced observability into our servers, databases, services, and tools"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Plan Builder"
              description="Custom training plan generator built to accommodate athletes' capabilities and goals"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Post Workout Surveys"
              description="Gathering data from our athletes to reinforce our data from workouts to feed ML"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Personas"
              description="Refining the way we look at athletes and are able to measure success"
            />
          </div>

          <hr></hr>

          <WorkHeader
            title="Intouch Insight - Lead Product Manager"
            description="A Customer Experience company looking to empower business decision making"
          />

          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Case Management"
              description="A system created to track and prioritize issues for employees to resolve"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Action Campaigns"
              description="Provide business with a list of actionable items to focus on for improving specific business metrics"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Dashboards"
              description="Visualizing business data to uncover trends and insights"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <WorkProject
              title="Surveys"
              description="Generatable, customizble surveys to collect data across the buisness"
            />
          </div>

          <h6>Other Roles</h6>

          <WorkOtherRole title="Intouch Insight - Product Manager" />

          <WorkOtherRole title="Intouch Insight - Lead Product Designer" />

          <WorkOtherRole title="Intouch Insight - Product Designer" />
        </div>
      </div>
    );
  }
}

export default Works;

// <Link
// to={{
//   pathname: `/work/${works[1].id}`
// }}
// >
// {works[1].name}
// </Link>
// <Link
// to={{
//   pathname: `/work/${works[0].id}`
// }}
// >
// {works[0].name}
// </Link>
