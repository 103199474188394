import React, { Component } from "react";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/about" });

class About extends Component {
  render() {
    return (
      <div id="about" className="container-md">
        <h1>About Me</h1>
        <h4>Hey there,</h4>
        <h1>Dominik Rymsza</h1>

        <br></br>
      </div>
    );
  }
}

export default About;
