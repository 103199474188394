import React, { Component, useLayoutEffect } from "react";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/contact" });

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 768 });
  }

  render() {
    const isDesktop = this.state.isDesktop;

    return (
      <div id="contact"
        className={
          "container-md d-flex p-0 " +
          (this.state.isDesktop ? "vh-100" : "")
        }
      >
        <div className="row">
          <div className="d-flex col-md-6 col-sm-12 align-items-md-center p-3">
            <h4 className="subtopic">
              Want to get in touch?
              <br></br>
              Send me a message at
              <br></br>
              <a href="mailto:dominikrymsza@gmail.com">
                dominikrymsza@gmail.com
              </a>
            </h4>
          </div>
          <div className="d-flex col-md-6 col-sm-12 align-items-md-center px-3">
            <p>
              For more details, updates, and information you can find me on me
              on{" "}
              <a href="https://www.linkedin.com/in/dominik-rymsza/">LinkedIn</a>
              , <a href="https://www.strava.com/athletes/341967">Strava</a>, and{" "}
              <a href="https://medium.com/@dombledorre">Medium</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
