import React, { Component } from "react";

class WorkHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="workHeader">
        <h3>{this.props.title}</h3>
      </div>
    );
  }
}

export default WorkHeader;
