import React, { Component } from "react";

class WorkOtherRole extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="workHeader">
        <h5>{this.props.title}</h5>
      </div>
    );
  }
}

export default WorkOtherRole;
