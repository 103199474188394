import React, { Component } from "react";

class WorkProject extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="workProject my-2 mb-4">
        <h5>{this.props.title}</h5>
        <p>{this.props.description}</p>
        <a className="d-none" href='#'>Read more</a>
      </div>
    );
  }
}

export default WorkProject;
