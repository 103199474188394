/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavFull from "./Navfull";

function toggle_menu() {
  var app = document
    .getElementsByTagName("BODY")[0]
    .classList.toggle("menu-open");
}

class Menu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="overlay-menu container-xl d-flex flex-column position-fixed fixed-top vh-100 w-100">
        <div className="d-flex row row-cols-auto py-4 align-items-center justify-content-end overlay-menu-header">
          <div className="me-auto">
            <Link
              className="myname"
              to={{
                pathname: `/`,
              }}
            >
              Dominik Rymsza
            </Link>
          </div>
          <div className="d-flex">
            <button className="menu-button-close" onClick={() => toggle_menu()}>
              <span></span> <span></span>
            </button>
          </div>
        </div>
        <div className="overlay-menu-content">
          <nav className="overlay-menu-main">
            <NavFull />
          </nav>
        </div>
        <div className="overlay-menu-footer">
          <nav className="social-menu">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/henryegloff"
                  aria-label="Instagram Profile"
                  target="_blank"
                >
                  <svg></svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/henryegloff"
                  aria-label="Youtube Profile"
                  target="_blank"
                >
                  <svg></svg>
                </a>
              </li>
              <li>
                <a
                  href="https://codepen.io/henryegloff"
                  aria-label="Codepen Profile"
                  target="_blank"
                >
                  <svg></svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.behance.net/henryegloff"
                  aria-label="Behance Profile"
                  target="_blank"
                >
                  <svg></svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/henryegloff"
                  aria-label="Twitter Profile"
                  target="_blank"
                >
                  <svg></svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Menu;
