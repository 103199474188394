import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/thoughts" });


class Thoughts extends Component {
  render() {
    return (
      <div id="thoughts" className="container-md">
        <h1>Thoughts</h1>
        <Link
                to={{
                  pathname: `/thought/${window.thoughts[1].id}`
                }}
              >
                {window.thoughts[1].name}
              </Link>
              <Link
                to={{
                  pathname: `/thought/${window.thoughts[0].id}`
                }}
              >
                {window.thoughts[0].name}
              </Link>
      </div>
    );
  }
}

export default Thoughts;
