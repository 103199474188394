import React, { Component } from "react";
import ReactGA from "react-ga4";

function toggle_light_mode(mode) {
  var app = document.getElementsByTagName("BODY")[0];
  if (localStorage.lightMode == "dark") {
    ReactGA.event({
      category: "Mode",
      action: "Toggle Light Mode"
    });
    localStorage.lightMode = "light";
    app.setAttribute("light-mode", "light");
  } else {
    ReactGA.event({
      category: "Mode",
      action: "Toggle Dark Mode"
    });
    localStorage.lightMode = "dark";
    app.setAttribute("light-mode", "dark");
  }
}

class ModeToggle extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var app = document.getElementsByTagName("BODY")[0];
    app.setAttribute("light-mode", "dark");

    if (localStorage.lightMode == "dark") {
      app.setAttribute("light-mode", "dark");
    }

    window.addEventListener(
      "storage",
      function() {
        if (localStorage.lightMode == "dark") {
          app.setAttribute("light-mode", "dark");
        } else {
          app.setAttribute("light-mode", "light");
        }
      },
      false
    );
  }

  render() {
    return (

        <button
          className="light-mode-button"
          aria-label="Toggle Light Mode"
          onClick={() => toggle_light_mode(localStorage.lightMode)}
        >
          <span></span>
          <span></span>
        </button>

    );
  }
}

export default ModeToggle;
