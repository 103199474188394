import React, { Component } from "react";
import WorkProject from "../components/WorkProject";
import WorkHeader from "../components/WorkHeader";
import WorkOtherRole from "../components/WorkOtherRole";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/home" });

class Home extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div id="home">
        <div className="container-md">
          <h3>
            I'm a Product Manager focusing on users with a great interest in
            data.
          </h3>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-12 mb-4">
              <WorkHeader
                title="TrainerRoad - Product Manager"
                description="A cycling based company leveraging ML to optimize and personalize training for athletes"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Adaptive Training"
                description="Adjusting training to give athletes the right workout everytime"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Datadog"
                description="Enabling enchanced observability into our servers, databases, services, and tools"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Plan Builder"
                description="Custom training plan generator built to accommodate athletes' capabilities and goals"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Post Workout Surveys"
                description="Gathering data from our athletes to reinforce our data from workouts to feed ML"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Personas"
                description="Refining the way we look at athletes and are able to measure success"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Tax Compliance"
                description="Modernizing out payment system to maintain and manage tax rates for athletes."
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Legacy Pricing Increase"
                description="Gracefully updating subscription plans to match the market for our legacy athletes"
              />
            </div>
          </div>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-12 mb-4">
              <WorkHeader
                title="Intouch Insight - Lead Product Manager"
                description="A Customer Experience company looking to empower business decision making"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Case Management"
                description="A system created to track and prioritize issues for employees to resolve"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Action Campaigns"
                description="Provide business with a list of actionable items to focus on for improving specific business metrics"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Dashboards"
                description="Visualizing business data to uncover trends and insights"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <WorkProject
                title="Surveys"
                description="Generatable, customizble surveys to collect data across the buisness"
              />
            </div>
          </div>
          <br></br>
          <br></br> <br></br>
          <br></br>
          <div className="row">
            <h6>
              Other Roles <br></br>
              <br></br>
            </h6>
            <WorkOtherRole title="Intouch Insight - Product Manager" />
            <WorkOtherRole title="Intouch Insight - Lead Product Designer" />
            <WorkOtherRole title="Intouch Insight - Product Designer" />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
