import React, { Component } from "react";
import NavItemFull from "./NavItemFull";

class NavFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NavItemActive: "",
    };
  }
  activeitem = (x) => {
    if (this.state.NavItemActive.length > 0) {
      document
        .getElementById(this.state.NavItemActive)
        .classList.remove("active");
    }
    this.setState({ NavItemActive: x }, () => {
      document.getElementById(this.state.NavItemActive).classList.add("active");
    });
  };
  render() {
    return (
      <>
        <nav className="">
          <ul className="">
            <NavItemFull
              classes=""
              item="Home"
              tolink="/"
              activec={this.activeitem}
            ></NavItemFull>
            <NavItemFull
              classes="d-none"
              item="Works"
              tolink="/Works"
              activec={this.activeitem}
            ></NavItemFull>
            <NavItemFull
              classes="d-none"
              item="About"
              tolink="/about"
              activec={this.activeitem}
            ></NavItemFull>
            <NavItemFull
              classes="d-none"
              item="Thoughts"
              tolink="/thoughts"
              activec={this.activeitem}
            ></NavItemFull>
            <NavItemFull
              classes=""
              item="Contact"
              tolink="/contact"
              activec={this.activeitem}
            ></NavItemFull>
          </ul>
        </nav>
      </>
    );
  }
}

export default NavFull;
