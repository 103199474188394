import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import Navbar from "./components/Navbar";
import Menu from "./components/Menu";
import MenuButton from "./components/MenuButton";
import Home from "./contents/Home";
import About from "./contents/About";
import Thoughts from "./contents/Thoughts";
import Thought from "./contents/Thought";
import Contact from "./contents/Contact";
import ModeToggle from "./components/ModeToggle";
import Work from "./contents/Work";
import Works from "./contents/Works";

function App() {
  ReactGA.initialize("G-GZPTQ7M9Q6");
  ReactGA.send("pageview");
  
  window.works = [
    { id: "1", name: "Work 1" },
    { id: "2", name: "Work 2" },
  ];

  window.thoughts = [
    { id: "1", name: "Thought 1" },
    { id: "2", name: "Thought 2" },
  ];

  return (
    <Router>
      <div>
        <div className="w-100 position-fixed">
          <div className="container-xl">
            <div className="d-flex row row-cols-auto py-4 align-items-center">
              <div className="me-auto">
                <Link
                  className="myname"
                  to={{
                    pathname: `/`,
                  }}
                >
                  Dominik Rymsza
                </Link>
              </div>
              <div className="d-flex justify-content-end">
                <Navbar />
              </div>
              <div className="d-flex align-items-center">
                <ModeToggle />
                <MenuButton></MenuButton>
              </div>
            </div>
            <Menu></Menu>
          </div>
        </div>
      </div>
      <div className="App container-xl min-vh-100">
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/thoughts">
          <Thoughts />
        </Route>
        <Route path="/thought/:id">
          <Thought />
        </Route>
        <Route path="/works">
          <Works />
        </Route>
        <Route path="/work/:id">
          <Work />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
      </div>
    </Router>
  );
}

export default App;
