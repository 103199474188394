import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/thought" });

const Thought = () => {
  let [data, setData] = useState([]);
  const params = useParams();
  const loadThought = useCallback(async () => {
    let thoughtUrl = "../thoughts/thought" + params.id + ".json";
    let response = await fetch(thoughtUrl);
    response = await response.json();
    setData(response);
  }, [params.id]);

  useEffect(() => {
    loadThought();
  }, [loadThought]);

  const theThought = window.thoughts.find((obj) => {
    return obj.id === "1";
  });

  console.log("theThought");
  console.log(theThought);

  return (
    <div id="thought" className="container-md">
      <h1>{data.thoughtHeader}</h1>
      <br></br>
      <p>
        Training Plans have been used by athletes in sport for a very long time.
        But unless you some instrumentation to measure your training and have a
        coach paying close attention, you’re likely not working optimally.
      </p>
      <p>
        TrainerRoad historically was one step past the stock and static training
        plan. Our athletes have instrumentation in place in the means of a smart
        trainer. This is essentially treadmill for bikes which is controlled
        allowing for cycling indoors. The smart trainer is attached to the wheel
        or cassette of the bike and can control the resistance applied. Based on
        a test we are able to calculate an athlete’s FTP (Functional Threshold
        Power). FTP is a measurement in cycling of how much power an athlete can
        produce in 1 hour. Using an athlete’s FTP, workouts and training plans
        are produced tailored to their unique capabilities.
      </p>
      <br></br>
      <br></br>
      <h3>Fundamental Changes</h3>
      <br></br>
      <p>
        <strong>Workout Levels:</strong> The level of a workout within a
        specific zone.
      </p>
      <p>
        Previously we just had workouts which worked a certain zone. There was
        no concept of how hard that workout is other than for someone with a
        good understanding of training. Simlpy put, Workout Levels are a ranking
        system to measure the difficulty of workouts. These are displayed as a
        number between 1 - 10 from easiest to hardest.
      </p>

      <p>
        <strong>Progression Levels:</strong> Personalized levels for athletes
        within different zones.
      </p>
      <p>
        With workout difficulty being quantified we could further tailor
        training for specific athletes. Each athlete had their own progression
        levels which increase and decreased based on their training. An athletes
        Progression Levels could then be used to fuel recommended workouts and
        more precisely create Training Plans.
      </p>

      <p>
        <strong>Workout Difficulties:</strong> How hard this workout is for you
        currently.
      </p>
      <p>
        Taking both Workout Levels and Progression Levels we can measure a
        difficulty. This allows athletes to prepare for the week ahead, making
        sure to know what days they may require some extra fueling or
        preparation for. It also eases the process for those selecting a workout
        for themselves.
      </p>

      <ul>
        <li>Recovery</li>
        <li>Achievable </li>
        <li>Productive</li>
        <li>Stretch</li>
        <li>Breakthrough</li>
        <li>Not Recommended</li>
      </ul>

      <p>
        <strong>Workout Outcomes:</strong> How hard this workout is for you
        currently.
      </p>
      <p>
        With workouts we have the luxury of a controlled environment where
        athletes attempt to perform a specific workout. This work performed by
        an athlete can be assessed, however, success and failure can be to
        varying degrees. The model took workouts and categorized them in 4
        primary outcomes:
      </p>

      <ul>
        <li>SuperPass</li>
        <li>Pass </li>
        <li>Struggle</li>
        <li>Fail</li>
      </ul>

      <p>
        As well as whether the Workout was CutShort. Workout Outcomes would be
        used to help improve flows for Post Workout Surveys and inform us
        whether workouts were too easy, too hard, or just right. This was a
        crucial piece for allowing us to iterate and improve the experience for
        athletes.
      </p>

      <br></br>
      <br></br>
      <h3>Features Released</h3>
      <br></br>
      <p>
        <strong>Adaptations:</strong> Updating athletes training based on unique
        athlete traits, performance, and schedule changes.
      </p>
      <p>
        When progressing through a Training Plan athletes are prompted to adapt
        their plan after key events happen which would require the adjustment of
        their plan.
      </p>

      <ul>
        <li>Athlete’s levels change faster or slower than expected </li>
        <li>Athlete misses workouts </li>
        <li>Athlete schedules which will result in missed workouts </li>
        <li>Athletes performance in workouts </li>
      </ul>

      <br></br>
      <br></br>
      <h3>Conclusions</h3>
      <br></br>
      <p>
        One of the challenges of releasing such a shift to the product with so
        many features is trying to understand the numbers to iterate and
        improve. How do we know a decrease or increase in athlete happiness is
        directly correlated to one or more of these?
      </p>
      <p>
      Adaptive Training is TrainerRoads first step into the ML/AI space. We we’re the first offering of it’s kind.
      </p>
      <p>
      Adaptive Training is TrainerRoads first step into the ML/AI space. We we’re the first offering of it’s kind.
      </p>
      <p>
      The new layer of features was received tremendously. 
      </p>

      <p>Since it’s release to production we have seen</p>
      <ul>
        <li>50% decrease in failed workouts year-over-year for athlete </li>
        <li>68% fewer not recommend workouts attempted</li>
        <li>M+1 churn reduced</li>
        <li>NPS increased w</li>
      </ul>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <hr></hr>
      <br></br>
      <br></br>

      <p>
        Learn more about the next feature that we pushed out
        <br></br>
        {/* <Link
          to={{
            pathname: `/thought/${data.thoughtNextLink}`,
          }}
        >
          {window.thoughts[theThought.id - 1].name}
        </Link> */}
        <Link
          to={{
            pathname: `/thought/${data.thoughtNextLink}`,
          }}
        >
          Adaptive Training - Fundamental Changes
        </Link>
      </p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Thought;
