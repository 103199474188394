/* eslint-disable no-unused-expressions */
import React, { Component } from "react";

function toggle_menu_button() {
  var app = document
    .getElementsByTagName("BODY")[0]
    .classList.toggle("menu-open");
}

class MenuButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button
        title="Open Menu"
        className="menu-button-open visible-xs-* d-block d-md-none"
        onClick={() => toggle_menu_button()}
      >
        <span></span> <span></span> <span></span>{" "}
      </button>
    );
  }
}

export default MenuButton;
