import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/work" });



const Work = () => {
  let [data, setData] = useState([]);
  const params = useParams();
  const loadWork = useCallback(async () => {
    let workUrl = "../works/work" + params.id + ".json";
    let response = await fetch(workUrl);
    response = await response.json();
    setData(response);
  }, [params.id]);

  useEffect(() => {
    loadWork();
  }, [loadWork]);

  return (
    <div id="work" className="container-md">
      <h1>{data.workHeader}</h1>
      {data.workHeader}
    </div>
  );
};

export default Work;
