import React, { Component } from 'react';
import { Link } from "react-router-dom";

function toggle_menu() {
    var app = document
      .getElementsByTagName("BODY")[0]
      .classList.toggle("menu-open");
  }

class NavItemFull extends Component {
    render() {
        return (
            <li id={this.props.item} className={this.props.classes}>
            <Link to={this.props.tolink} 
                onClick={(event) => {
                    this.props.activec.bind(this,this.props.item);
                    toggle_menu();
                }}>{this.props.item}</Link>
            </li>
            )
    }
}
        
        export default NavItemFull
        